<template>
  <v-container>
    <v-row class="mt-0">
      <v-text-field
        label="Search companies"
        hide-details="auto"
        v-model="search_string"
        @input="searchCompaniesDebounced"
        debounce="1000"
      ></v-text-field>
    </v-row>
    <v-row>
      <v-col>
        <v-list dense v-if="returned_stocks.length > 0" width="100%">
          <v-list-item-group>
            <v-list-item
              v-for="stock in returned_stocks"
              :key="stock.ticker"
              :to="{ name: 'stats', params: { ticker: stock.symbol } }"
            >
              <v-list-item-content>
                <v-list-item-title
                  >{{ stock.description }}({{
                    stock.symbol
                  }})</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Home',
  data() {
    return {
      axiosinstance: axios.create({
        baseURL: 'https://sandbox.tradier.com/v1/',
        headers: { Authorization: 'Bearer YTG1TsfXbs1OWMGnWZo7WInkeenW' },
      }),
      search_string: '',
      returned_stocks: [],
      timerId: 'debounceTimer',
    };
  },
  methods: {
    searchCompaniesDebounced() {
      // cancel pending call
      clearTimeout(this.timerId);

      // delay new call 500ms
      this.timerId = setTimeout(() => {
        this.searchCompanies();
      }, 500);
    },
    searchCompanies() {
      this.returned_stocks = [];
      if (this.search_string !== '') {
        this.axiosinstance
          .get('markets/search', {
            params: {
              q: this.search_string,
            },
          })
          .then((response) => {
            // eslint-disable-next-line
            this.returned_stocks =
              response.data.securities?.security?.length > 0
                ? response.data.securities?.security ?? []
                : [response.data.securities?.security];
          });
      }
    },
  },
};
</script>
