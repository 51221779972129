<template>
  <v-container>
    <h1>{{ stock_stats[0].description }} ({{ stock_stats[0].symbol }})</h1>
    <v-text-field
      v-model="percent_change"
      type="number"
      label="Percent +-"
      @input="calcData"
    >
    </v-text-field>
    <v-data-table
      width="100%"
      :headers="headers"
      :items="stock_stats"
      :items-per-page="5"
      class="elevation-1"
    ></v-data-table>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Stats',
  data() {
    return {
      axiosinstance: axios.create({
        baseURL: 'https://sandbox.tradier.com/v1/',
        headers: { Authorization: 'Bearer YTG1TsfXbs1OWMGnWZo7WInkeenW' },
      }),
      stock_stats: [{}],
      headers: [
        {
          text: 'Strat',
          align: 'start',
          value: 'strat'
        },
        {
          text: 'Name',
          align: 'start',
          value: 'description',
        },
        { text: 'Ticker', value: 'symbol' },
        { text: 'price', value: 'price' },
        { text: 'Price Variant', value: 'price_variance' },
        { text: 'Weekly/Monthly Options', value: 'weeklys' },
        { text: 'Nearest Option Date', value: 'near_date' },
        { text: 'Nearest Option Strike', value: 'near_option_strke' },
        { text: 'Nearest Option Value', value: 'near_option_value' },
        { text: 'Nearest Option Return %', value: 'near_option_return_percent' },
        { text: '7WK Option Date', value: 'far_date' },
        { text: '7WK Option Strike', value: 'far_option_strke' },
        { text: '7WK Option Value', value: 'far_option_value' },
        { text: '7WK Option Return %', value: 'far_option_return_percent' },
      ],
      percent_change: 5,
      expirations: [],
      quote: {},
      nearest_chain_calls: [],
      nearest_chain_puts: [],
      next_month_chain_calls: [],
      next_month_chain_puts: [],
      price: 0,
      price_variance: 0,
      lead: 0,
      lead2: 0,
    };
  },
  computed: {
    ticker() {
      return this.$route.params.ticker;
    },
  },
  async mounted() {
    this.getData();
  },
  methods: {
    formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    },
    daysBetween(one, another) {
      return Math.round(Math.abs(+one - +another) / 8.64e7);
    },
    async quoteData() {
      return this.axiosinstance
        .get('/markets/quotes', {
          params: {
            symbols: this.ticker,
          },
        })
        .then((response) => {
          this.quote = response.data.quotes.quote;
        });
    },
    async expirationData() {
      return this.axiosinstance
        .get('/markets/options/expirations', {
          params: {
            symbol: this.ticker,
          },
        })
        .then((response) => {
          this.expirations = response.data.expirations.date;
        });
    },
    async getFirstChain(expirationDate) {
      return this.axiosinstance
        .get('/markets/options/chains', {
          params: {
            symbol: this.ticker,
            expiration: expirationDate,
          },
        })
        .then((response) => {
          this.nearest_chain_calls = response.data.options.option.filter(
            (item) => item.option_type === 'call',
          );
          this.nearest_chain_puts = response.data.options.option.filter(
            (item) => item.option_type === 'put',
          );
        });
    },
    async getSecondChain(expirationDate) {
      return this.axiosinstance
        .get('/markets/options/chains', {
          params: {
            symbol: this.ticker,
            expiration: expirationDate,
          },
        })
        .then((response) => {
          this.next_month_chain_calls = response.data.options.option.filter(
            (item) => item.option_type === 'call',
          );
          this.next_month_chain_puts = response.data.options.option.filter(
            (item) => item.option_type === 'put',
          );
        });
    },
    calcData() {
      this.price = this.quote.last.toFixed(2);
      this.price_variance = (
        this.quote.last * (this.percent_change / 100) +
        this.quote.last
      ).toFixed(2);
      const firstChainNearestOptionStrike = this.nearest_chain_calls
        .map((item) => item.strike)
        .filter((item) => item > this.price_variance)
        .reduce((a, b) =>
          Math.abs(b - this.price_variance) < Math.abs(a - this.price_variance) ? b : a,
        );
      const secondChainNearestOptionStrike = this.next_month_chain_calls
        .map((item) => item.strike)
        .filter((item) => item > this.price_variance)
        .reduce((a, b) =>
          Math.abs(b - this.price_variance) < Math.abs(a - this.price_variance) ? b : a,
        );
      const firstChainNearestOption = this.nearest_chain_calls.find(
        (item) => item.strike === firstChainNearestOptionStrike,
      );
      const secondChainNearestOption = this.next_month_chain_calls.find(
        (item) => item.strike === secondChainNearestOptionStrike,
      );
      this.stock_stats = [];
      let quoteArray = [];
      const quoteCopy = { ...this.quote };
      quoteArray = [
        Object.assign(quoteCopy, {
          price: this.price,
          price_variance: this.price_variance,
          weeklys: this.weeklys,
          near_date: this.expirations[this.lead],
          near_option_strke: firstChainNearestOptionStrike,
          near_option_value: (
            (firstChainNearestOption.ask + firstChainNearestOption.bid) /
            2
          ).toFixed(2),
          near_option_return_percent: (
            ((((firstChainNearestOption.ask + firstChainNearestOption.bid) / 2) * 100) /
              (this.price * 100)) *
            100
          ).toFixed(4),
          far_date: this.expirations[this.lead2],
          far_option_strke: secondChainNearestOptionStrike,
          far_option_value: (
            (secondChainNearestOption.ask + secondChainNearestOption.bid) /
            2
          ).toFixed(2),
          far_option_return_percent: (
            ((((secondChainNearestOption.ask + secondChainNearestOption.bid) / 2) * 100) /
              (this.price * 100)) *
            100
          ).toFixed(4),
          strat: "Call Sells"
        }),
      ];
      this.stock_stats = quoteArray;
    },
    async getData() {
      const quote = this.quoteData();
      const expirations = this.expirationData();
      await Promise.all([quote, expirations]);
      this.weeklys =
        this.daysBetween(new Date(this.expirations[0]), new Date(this.expirations[1])) > 10
          ? 'Monthly'
          : 'Weekly';
      this.lead = this.formatDate(new Date()) == this.expirations[0] ? 1 : 0;

      const firstChain = this.getFirstChain(this.expirations[this.lead]);
      this.lead2 = this.weeklys === 'Monthly' ? this.lead + 1 : this.lead + 6;
      const secondChain = this.getSecondChain(this.expirations[this.lead2]);
      await Promise.all([firstChain, secondChain]);
      this.calcData();
    },
  },
};
</script>
